import { useState } from 'react'
import { AlbumFile } from '@/models/albumFile.ts'

export interface FileSelectionType {
  toggleFileSelection: (file: AlbumFile) => void;
  isSelecting: boolean;
  isAllSelected: boolean;
  setIsSelecting: (selected: boolean) => void;
  setIsAllSelected: (value: boolean) => void
  getSelectedAlbumFileIds: () => Array<string>
  isFileSelected: (file: AlbumFile) => boolean
  maxFiles?: number
  setMaxFiles: (maxFiles?: number) => void
}

export default function useFileSelection({ initialIsSelecting }: {initialIsSelecting?: boolean} = {}): FileSelectionType {
  const [isSelecting, _setIsSelecting] = useState(initialIsSelecting !== undefined ? initialIsSelecting : false)
  const [isAllSelected, setIsAllSelected] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState<Record<string, boolean>>({})
  const [maxFiles, setMaxFiles] = useState<number | undefined>(undefined)

  function setIsSelecting(selected: boolean) {
    if (selected) {
      // Reset status
      setIsAllSelected(false)
      setSelectedFiles({})
    }

    _setIsSelecting(selected)
  }

  function isFileSelected(file: AlbumFile): boolean {
    return isAllSelected || !!selectedFiles[file.id]
  }

  function toggleFileSelection(file: AlbumFile) {
    const isSelected = isFileSelected(file)
    if (maxFiles !== undefined && !isSelected && getSelectedAlbumFileIds().length + 1 > maxFiles) {
      return
    }
    setSelectedFiles({
      ...selectedFiles,
      [file.id]: !isSelected,
    })
  }

  function getSelectedAlbumFileIds(): Array<string> {
    return Object.keys(selectedFiles).filter(file => selectedFiles[file])
  }

  return {
    isSelecting,
    setIsSelecting,
    isAllSelected,
    setIsAllSelected,
    toggleFileSelection,
    getSelectedAlbumFileIds,
    isFileSelected,
    maxFiles,
    setMaxFiles,
  }
}

export function getFilterFromSelection(fileSelection: FileSelectionType): Array<Array<string>> {
  const selectedFiles = fileSelection.getSelectedAlbumFileIds()
  const { isAllSelected } = fileSelection
  if (isAllSelected) {
    return []
  }

  if (selectedFiles.length <= 0) {
    throw 'no selection'
  }

  return [['ids', selectedFiles.join(',')]]
}
