import { FilesCollection } from '@/stores/useFiles.ts'
import { AlbumFile } from '@/models/albumFile.ts'
import { AlbumFileType } from '@/models/albumFileType.ts'
import { IconCircleCheck, IconHeartFilled, IconPlayerPlay, IconVideo } from '@tabler/icons-react';
import { ReactNode, useMemo } from 'react'
import { FileSelectionType } from '@/hooks/useFileSelection.tsx'
import { useProfile } from '@/stores/useAuth.ts'
import { HeyProfile } from '@/models/profile.ts'

export default function FilesListing({ files, onFileClick, prependChild, fileSelection }: { files: FilesCollection, onFileClick: (file: AlbumFile) => void, prependChild?: ReactNode, fileSelection?: FileSelectionType }) {
  const {profile} = useProfile()
  return <div className='grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-1'>
    { prependChild }
    { files.files.map(file => <FilesListingComponent
      key={file.id}
      file={file}
      onClick={onFileClick}
      isSelected={!!fileSelection?.isSelecting && fileSelection.isFileSelected(file)}
      profile={profile}
    />) }
  </div>
}

function secondsToTime(e: number){
  const h = Math.floor(e / 3600).toString().padStart(2,'0'),
    m = Math.floor(e % 3600 / 60).toString().padStart(2,'0'),
    s = Math.floor(e % 60).toString().padStart(2,'0');

  if (h == '00') {
    return m + ':' + s;
  }

  return h + ':' + m + ':' + s;
}

function LikoComponent() {
  return <div className='text-primary absolute left-2 bottom-2'>
    <IconHeartFilled />
  </div>
}

function FilesListingComponent({ file, onClick, isSelected, profile }: { file: AlbumFile, onClick: (file: AlbumFile) => void, isSelected: boolean, profile: HeyProfile | null }) {
  const yourLike = file.likes && profile ? file.likes.find(file => file.author === profile.username) : null
  if (file.file_type === AlbumFileType.IMAGE) {
    return <div
      className='aspect-square bg-cover bg-center cursor-pointer bg-secondary relative'
      style={{ backgroundImage: `url("${file.thumbnail}")` }}
      onClick={() => onClick(file)}
    >
      { isSelected && <SelectedFileOverlay /> }
      { yourLike && <LikoComponent /> }
    </div>
  }

  const videoDurationString = useMemo(() => {
    if (file.video_duration_seconds == null) {
      return null
    }

    return secondsToTime(file.video_duration_seconds)
  }, [file.video_duration_seconds])

  if (file.file_type === AlbumFileType.VIDEO) {
    const style = file.thumbnail ? { backgroundImage: `url("${file.thumbnail}")` } : undefined
    return <div
      className='aspect-square bg-cover bg-center cursor-pointer bg-secondary flex items-center justify-center text-primary relative'
      style={style}
      onClick={() => onClick(file)}
    >
      { !file.thumbnail && <IconVideo size={50}/> }
      { file.thumbnail && <div className='border-4 border-neutral rounded-full aspect-square p-2'>
        <IconPlayerPlay className='text-neutral' size={40} />
      </div> }
      { videoDurationString && <div className='absolute bg-[black] bg-opacity-80 py-[1px] px-[5px] rounded bottom-1 right-1 text-[0.75rem] text-[white]'>{ videoDurationString }</div> }
      { isSelected && <SelectedFileOverlay /> }
      { yourLike && <LikoComponent /> }
    </div>
  }
}

function SelectedFileOverlay() {
  return <div className='absolute w-full h-full top-0 left-0 bg-[black] bg-opacity-30 flex items-end justify-end p-1'>
    <IconCircleCheck className='bg-primary rounded-full text-neutral' size={24} />
  </div>
}
