import { useFilesContext } from '@/contexts/filesContext.tsx'
import { useState } from 'react'
import DriveHeader from '@/components/DriveHeader.tsx'
import { Button, Input } from 'react-daisyui'
import LoadBig from '@/components/LoadBig/LoadBig.tsx'
import { useClient } from '@/stores/useClient.ts'
import { t } from 'i18next'
import { Trans } from 'react-i18next'
import { useDrives } from '@/stores/useDrives.ts'
import { HeyDriveType } from '@/models/drive.ts'

export default function YesIDoPage() {
  const { currentDrive } = useDrives()
  const { currentAlbum: album } = useFilesContext()!
  const [email, setEmail] = useState('')
  const [isSending, setIsSending] = useState(false)
  const [wasSent, setWasSent] = useState(false)
  const { sendCoupon } = useClient()

  async function onSendSubmit(e: any) {
    e.preventDefault()
    e.stopPropagation()
    if (isSending) {
      return
    }

    setIsSending(true)
    try {
      await sendCoupon(currentDrive!.id, email)
      setWasSent(true)
    } catch (e) {
      console.error('error sending coupon', e)
      alert('Beim Senden ist etwas fehlgeschlagen. Bitte probiere es erneut.')
    } finally {
      setIsSending(false)
    }
  }

  if (!album || !currentDrive) {
    return <LoadBig />
  }

  return <div>
    <DriveHeader title={currentDrive.drive_type === HeyDriveType.WEDDING ? (t('common.yesIDo') + '...') : null} description=' ' />
      <div className='container px-4 mx-auto flex flex-col gap-3 items-center max-w-[25rem] text-center'>
        <p className='text-xl'>
          {
            currentDrive.drive_type === HeyDriveType.WEDDING ? t('common.alsoWantFridaysnap') : t('common.alsoWantFridaysnapGeneral')
          } 😍
        </p>
        <p>
          {t('common.yesAppreciate')}
        </p>
        <form className='bg-primary p-5 rounded-xl flex flex-col gap-3 min-w-[20rem] py-[2rem]' onSubmit={onSendSubmit}>
          <span className='text-xl font-bold text-neutral'>{t('common.couponPc', { percent: '10' })}</span>
          { !wasSent && <>
            <p className='text-neutral'>
              <Trans i18nKey='common.couponSecure' components={{ 1: <br /> }} values={{ percent: '10' }} />
            </p>
            <div>
              <Input type='email' required color='primary' placeholder={t('common.yourEmail')} value={email} onChange={e => setEmail(e.target.value)} />
            </div>
            <div className='mt-5'>
              <Button className='text-neutral' color='success' disabled={isSending} loading={isSending}>
                {t('common.couponReceive')}
              </Button>
            </div>
          </> }
          {
            wasSent && <p className='text-neutral'>
              {t('common.couponSent')}
            </p>
          }
        </form>
      </div>
    </div>
  }
