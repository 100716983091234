import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { BytesHistoryPoint, UploadFile, UploadFileStatus, useUploadQueue } from '@/stores/useUploadQueue.ts'
import { useClient } from '@/stores/useClient.ts'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { AlbumFileUpload } from '@/models/albumFileUpload.ts'
import { AlbumFile } from '@/models/albumFile.ts'
import { captureException, sentryFileData, sentryResponseError } from '@/controller/sentryHelper.ts'

const UploadContext = createContext<UploadContextType>({
  currentFile: null,
})

interface UploadContextType {
  currentFile: UploadFile | null
}

export function useUpload() {
  return useContext(UploadContext)
}

function findNextFile(uploadQueue: Array<UploadFile>): UploadFile | undefined {
  return uploadQueue.find(file => file.status === UploadFileStatus.Added)
}

interface MultipartUploadPart {
  part: number
  content_offset: number
  content_length: number
  post_url: string
}

const MAX_BYTES_HISTORY_LENGTH = 30

export const UploadProvider = ({ children }: { children: React.ReactNode }) => {
  const { uploadQueue, updateFileUpload, addUploadedFiles } = useUploadQueue()
  const [currentFile, setCurrentFile] = useState<UploadFile | null>(null)
  const { createFileUpload, completeFileUpload, completeFileMultipartUpload } = useClient()

  const processFile = useCallback(async (file: UploadFile) => {
    // file.status = UploadFileStatus.HashCompleted
    console.log('processFile', file.file.name)
    let fileState = file
    updateFileUpload({
      ...fileState,
      status: UploadFileStatus.InProgress
    })

    try {
      let hash: string | null = null
      const hashCalculationEnabled = false
      if (hashCalculationEnabled && file.file.size <= 1024 * 1024 * 20) {
        try {
          const digest = await crypto.subtle.digest('SHA-256', await file.file.arrayBuffer())
          const hashArray = Array.from(new Uint8Array(digest));
          hash = hashArray.map((b) => b.toString(16).padStart(2, "0")).join('')
        } catch (e) {
          captureException(e, 'Calculating hash', () => sentryFileData(file.file))
          console.error('Failing calculating hash', e)
        }
      }

      fileState = {
        ...fileState,
        fileHash: hash,
        status: UploadFileStatus.HashCompleted,
      }
      updateFileUpload(fileState)

      let res: AxiosResponse
      try {
        res = await createFileUpload(fileState.album, fileState, fileState.game)
      } catch (e) {
        captureException(e, 'Creating file upload', () => ({
          ...sentryResponseError(e as AxiosError),
          ...sentryFileData(file.file),
        }))

        throw e
      }

      const uploadData: {
        post_url?: string
        multipart_upload?: { part_size: number, parts: Array<MultipartUploadPart> }
        album_file_upload: AlbumFileUpload
      } = res.data
      fileState = {
        ...fileState,
        uploadPost: uploadData.post_url,
        status: UploadFileStatus.Uploading,
        bytesHistory: [],
      }
      updateFileUpload(fileState)
      console.log('RES:', res.data)
      let albumFile: AlbumFile
      try {
        if (uploadData.post_url) {
          const uploadRes = await axios.post(uploadData.post_url, fileState.file, {
            headers: {
              'Content-Type': fileState.file.type,
            },
            onUploadProgress: (e) => {
              const bytesUpload: BytesHistoryPoint = {
                time: Date.now(),
                bytes: e.loaded,
              }
              fileState = {
                ...fileState,
                bytesUploaded: e.loaded,
                bytesHistory: [bytesUpload, ...(fileState.bytesHistory || []).slice(0, MAX_BYTES_HISTORY_LENGTH)],
              }
              updateFileUpload(fileState)
            },
          })
          console.log('upRes', uploadRes.data)

          try {
            const completeRes = await completeFileUpload(uploadData.album_file_upload)
            console.log('completeRes', completeRes)
            albumFile = completeRes.data
          } catch (e) {
            captureException(e, 'Completing file upload', () => ({
              ...sentryResponseError(e as AxiosError),
              ...sentryFileData(file.file),
            }))

            throw e
          }

        } else if (uploadData.multipart_upload) {
          const etags: Array<{
            part_number: number,
            etag: string,
          }> = []
          for (let i = 0; i < uploadData.multipart_upload.parts.length; i++){
            const uploadPart = uploadData.multipart_upload.parts[i]

            const multipartUploadRes = await axios.put(uploadPart.post_url, fileState.file.slice(uploadPart.content_offset, uploadPart.content_offset + uploadPart.content_length), {
              headers: {
                'Content-Type': fileState.file.type,
              },
              onUploadProgress: e => {
                const bytesUpload: BytesHistoryPoint = {
                  time: Date.now(),
                  bytes: e.loaded,
                }

                fileState = {
                  ...fileState,
                  bytesUploaded: i * uploadData.multipart_upload!.part_size + e.loaded,
                  bytesHistory: [bytesUpload, ...(fileState.bytesHistory || []).slice(0, MAX_BYTES_HISTORY_LENGTH)],
                }
                updateFileUpload(fileState)
              },
            })
            console.log('multipartUpRes', multipartUploadRes.data)
            etags.push({
              part_number: uploadPart.part,
              etag: multipartUploadRes.headers['etag'],
            })
          }

          try {
            const completeRes = await completeFileMultipartUpload(uploadData.album_file_upload, etags)
            console.log('completeMultipartUploadRes', completeRes)
            albumFile = completeRes.data
          } catch (e) {
            captureException(e, 'Completing file multipart upload', () => ({
              ...sentryResponseError(e as AxiosError),
              ...sentryFileData(file.file),
            }))

            throw e
          }


        } else {
          throw 'invalid file response without multipart or post_url'
        }
      } catch (e) {
        captureException(e, 'Uploading file', () => ({
          ...sentryResponseError(e as AxiosError),
          ...sentryFileData(file.file),
        }))

        throw e
      }

      fileState = {
        ...fileState,
        status: UploadFileStatus.Success,
      }
      updateFileUpload(fileState)

      addUploadedFiles(file.album, [albumFile])
    } catch (e) {
      updateFileUpload({
        ...fileState,
        status: UploadFileStatus.Failed,
      })

      captureException(e, 'Internal uploading file uncaught', () => ({
        ...sentryFileData(fileState.file),
      }))

      throw  e
    }
  }, [updateFileUpload, createFileUpload, completeFileUpload, addUploadedFiles])

  useEffect(() => {
    if (!currentFile) {
      const nextFile = findNextFile(uploadQueue)
      if (nextFile) {
        setCurrentFile(nextFile)
      }
    }
  }, [uploadQueue, currentFile, setCurrentFile, processFile]);

  useEffect(() => {
    if (currentFile && currentFile.status === UploadFileStatus.Added) {
      processFile(currentFile)
        .catch(e => {
          captureException(e, 'Uploading file uncaught', () => ({
            ...sentryFileData(currentFile.file),
          }))
        })
        .finally(() => {
          setCurrentFile(null)
        })
    }
  }, [currentFile]);


  return <UploadContext.Provider value={{
    currentFile,
  }}>
    { children }
  </UploadContext.Provider>
}

