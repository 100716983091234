import { useNavigate, useParams } from 'react-router-dom'
import DriveHeader from '@/components/DriveHeader.tsx'
import { IconArrowBack, IconMinus, IconPlus } from '@tabler/icons-react'
import { t } from 'i18next'
import { useOrderItems } from '@/contexts/orderItemContext.tsx'
import { useProductConfiguration } from '@/contexts/playlistConfigurationContext.tsx'
import { Button } from 'react-daisyui'
import { useEffect, useState } from 'react'
import AlbumSelection from '@/components/AlbumSelection.tsx'
import { useDrives } from '@/stores/useDrives.ts'
import { FilesProvider, useFilesContext } from '@/contexts/filesContext.tsx'
import { Album } from '@/models/album.ts'
import FilesListing from '@/components/Files/FilesListing.tsx'
import { AlbumFileType } from '@/models/albumFileType.ts'
import { useInView } from 'react-intersection-observer'
import FilesSlideshow from '@/components/Files/FilesSlideshow.tsx'
import { AlbumFile } from '@/models/albumFile.ts'
import useFileSelection, { getFilterFromSelection } from '@/hooks/useFileSelection.tsx'
import FileSelection from '@/components/FileSelection.tsx'
import OrderItem from '@/models/orderItem.ts'
import { useClient } from '@/stores/useClient.ts'
import { captureException } from '@/controller/sentryHelper.ts'
import { useQueryClient } from '@tanstack/react-query'
import { FilesCollection } from '@/stores/useFiles.ts'
import { calculateTotalPrice, formatPrice } from '@/models/productConfiguration.ts'
import { PlaylistConfigurationOptionsElement } from '@/pages/ShopProduct.page.tsx'
import OrderItemStatus from '@/models/orderItemStatus.ts'

export default function OrderItemPage() {
  const {orderItemId} = useParams()
  const {getOrderItem} = useOrderItems()
  const {getConfiguration} = useProductConfiguration()
  const [isAdding, setIsAdding] = useState(false)
  const [isConfirmBuy, setIsConfirmBuy] = useState(false)
  const [isBuying, setIsBuying] = useState(false)
  const { buyOrderItem } = useClient()
  const queryClient = useQueryClient()
  const { currentDrive } = useDrives()


  const navigate = useNavigate()

  function onBackClick() {
    navigate('..')
  }

  const orderItem = getOrderItem(orderItemId!)
  if (!orderItem) {
    return <div>
      <DriveHeader title={null}/>
      <div className='container px-4 mx-auto flex flex-col gap-3 items-start relative mt-[1rem]'>
        <p>Nicht gefunden</p>
      </div>
    </div>
  }

  const configuration = getConfiguration(orderItem.kind)!

  const priceTag = formatPrice(calculateTotalPrice(configuration, orderItem as unknown as Record<string, string>))

  async function onBuyClick() {
    if (isBuying) {
      return
    }
    try {
      await buyOrderItem(orderItem!)
      await queryClient.invalidateQueries({
        queryKey: ['drive', currentDrive?.id, 'orderItems'],
      })
    } catch (e) {
      captureException(e, 'buying order')
      alert('Failed confirming buy' + JSON.stringify(e))
    } finally {
      setIsBuying(false)
    }
  }

  return <div>
    <DriveHeader title={null}/>
    <div className='container px-4 mx-auto flex flex-col gap-3 items-start relative mt-[1rem]'>
      <div className='text-sm text-primary' onClick={onBackClick}>
        <IconArrowBack/>
        {t('common.back')}
      </div>
      <div className='flex flex-col gap-3 bg-secondary p-3 rounded w-full'>
        <h1 className='text-2xl font-bold'>{configuration.title}</h1>

        <p>{configuration.description}</p>
        <div className='h-[1px] bg-neutral shadow'></div>
        <h2 className='font-bold text-xl'>Details</h2>
        <PlaylistConfigurationOptionsElement configuration={configuration} chosenOptions={orderItem as unknown as Record<string, string>} />
        <span className='flex flex-row items-center gap-2'>
          <span className='font-bold text-xl'>{priceTag}</span> <span className='text-sm opacity-50'>inkl. MwSt.</span>
        </span>
        {
          orderItem.order_item_status == OrderItemStatus.IN_DESIGN && <div>
            {
              orderItem.playlist.files.length > 0 && <>
                { !isConfirmBuy && <Button className='px-5' color='primary' onClick={() => setIsConfirmBuy(true)}>Jetzt kaufen</Button> }
                { isConfirmBuy && <div className='flex flex-row gap-3'>
                  <Button loading={isBuying} disabled={isBuying} className='px-5' color='primary' onClick={onBuyClick}>Kauf bestätigen</Button>
                  <Button disabled={isBuying} className='px-5' color='primary' variant='outline' onClick={() => setIsConfirmBuy(false)}>Kauf abbrechen</Button>
                </div>}
                <p className='text-sm mt-2'>Nach dem Kauf prüfen wir deine Fotobuch-Vorlage.<br />Erst nach deiner finalen Bestätigung geben wir es in den Druck.</p>
              </>
            }
            { orderItem.playlist.files.length === 0 && <p>Bitte füge Bilder hinzu</p> }
          </div>
        }
        {
          orderItem.order_item_status == OrderItemStatus.IN_CHECK && <div>
            <p>Vielen Dank! Wir überprüfen dein Design. Wir melden uns im Anschluss per Mail mit einem Zahlungslink.</p>
          </div>
        }
        <div className='h-[1px] bg-neutral shadow'></div>
        <h2 className='font-bold text-xl'>Ausgewählte Bilder</h2>
        <span>{orderItem.playlist.files.length} von {orderItem.image_limit} Bildern ausgewählt</span>
        <OrderItemFiles orderItem={orderItem} />
        <div className='h-[1px] bg-neutral shadow'></div>
        {
          orderItem.order_item_status === OrderItemStatus.IN_DESIGN &&
          <div>
            { isAdding && <Button color='primary' variant='outline' onClick={() => setIsAdding(false)}>
                Bilder hinzufügen abbrechen
              </Button>
            }
            {
              !isAdding && <Button color='primary' onClick={() => setIsAdding(!isAdding)}><IconPlus/> Bilder hinzufügen</Button>
            }
          </div>
        }
        {
          isAdding && <ImageSelection orderItem={orderItem}/>
        }
      </div>
    </div>
  </div>
}

function OrderItemFiles({orderItem}: { orderItem: OrderItem }) {
  const [selectedFileIndex, setSelectedFileIndex] = useState<number | null>(null)
  const fileSelection = useFileSelection()
  const {isSelecting, setIsSelecting, toggleFileSelection} = fileSelection
  const {removePlaylistFiles} = useClient()
  // const [showSelect, setShowSelect] = useState(false)
  const [isRemoving, setIsRemoving] = useState(false)
  const queryClient = useQueryClient()
  const {currentDrive} = useDrives()

  const currentFilesCollection: FilesCollection = {
    files: orderItem.playlist.files.map(({file}) => file),
  }

  function onFileClick(file: AlbumFile) {
    const indexOfFile = currentFilesCollection.files.findIndex(f => f.id === file.id)
    if (indexOfFile != null) {
      setSelectedFileIndex(indexOfFile)
    }
  }

  async function onRemoveClick() {
    if (isRemoving) {
      return
    }
    try {
      await removePlaylistFiles(orderItem.playlist, orderItem.playlist.files.filter(connection => fileSelection.isFileSelected(connection.file)))
      await queryClient.invalidateQueries({
        queryKey: ['drive', currentDrive?.id, 'orderItems'],
      })
      setIsSelecting(false)
    } catch (e) {
      captureException(e, 'removing files')
      alert('Failed removing files ' + JSON.stringify(e))
    } finally {
      setIsRemoving(false)
    }
  }

  function getSelectionOptions() {
    if (orderItem.order_item_status !== OrderItemStatus.IN_DESIGN) {
      return null
    }

    if (fileSelection.getSelectedAlbumFileIds().length > 0) {
      return <Button color='primary' onClick={onRemoveClick}>
        <IconMinus />
        Bilder entfernen
      </Button>
    }

    return null
  }

  return <div className='flex flex-col gap-3'>
    {
      orderItem.order_item_status == OrderItemStatus.IN_DESIGN && orderItem.playlist.files.length > 0 &&
      <div>
        { isSelecting && <Button color='primary' variant='outline' onClick={() => setIsSelecting(false)}>Bilder entfernen abbrechen</Button> }
        { !isSelecting && <Button color='primary' onClick={() => setIsSelecting(true)}><IconMinus /> Bilder entfernen</Button> }
      </div>
    }
    {/*{*/}
    {/*  showSelect && <div className='flex flex-row gap-3 px-2 mb-3'>*/}
    {/*    <div className='flex-1'></div>*/}
    {/*      <Button className='rounded-full' disabled={isRemoving} size='sm' color='primary'*/}
    {/*              onClick={() => {*/}
    {/*                setIsSelecting(!isSelecting)*/}
    {/*                fileSelection.setMaxFiles(orderItem.image_limit - orderItem.playlist.files.length)*/}
    {/*              }}>*/}
    {/*        {isSelecting ? t('common.cancel').toLowerCase() : t('common.select').toLowerCase()}*/}
    {/*      </Button>*/}
    {/*  </div>*/}
    {/*}*/}
    <FilesListing
      files={currentFilesCollection}
      onFileClick={isSelecting ? toggleFileSelection : onFileClick}
      fileSelection={fileSelection}
    />
    <FilesSlideshow files={currentFilesCollection.files} selectedFileIndex={selectedFileIndex}
                    onSelectFileIndex={setSelectedFileIndex}/>
    {
      isSelecting && <FileSelection fileSelection={fileSelection} hideAll hideOptions options={getSelectionOptions()}/>
    }
  </div>
}

function ImageSelection({orderItem}: { orderItem: OrderItem }) {
  const {currentDrive} = useDrives()
  const {currentAlbum: currentOuterAlbum} = useFilesContext()!
  const [currentAlbum, setCurrentAlbum] = useState(currentOuterAlbum!)

  return <div className=''>
    <div className='mt-3'>
      <AlbumSelection drive={currentDrive!} selectedAlbum={currentAlbum!}
                      setSelectedAlbum={(album: Album) => setCurrentAlbum(album)}/>
    </div>
    <FilesProvider albumId={currentAlbum.id} filesFilter={{media: AlbumFileType.IMAGE}}>
      <ImageSelectionImageView orderItem={orderItem}/>
    </FilesProvider>
  </div>
}

function ImageSelectionImageView({orderItem}: { orderItem: OrderItem }) {
  const {currentFilesCollection, hasNextPage, fetchNextPage} = useFilesContext()!
  const {ref, inView} = useInView()
  const [selectedFileIndex, setSelectedFileIndex] = useState<number | null>(null)
  const fileSelection = useFileSelection({initialIsSelecting: true})
  const {isSelecting, setIsSelecting, toggleFileSelection} = fileSelection
  const {addPlaylistFiles} = useClient()
  const [isAdding, setIsAdding] = useState(false)
  const queryClient = useQueryClient()
  const {currentDrive} = useDrives()

  function onFileClick(file: AlbumFile) {
    const indexOfFile = currentFilesCollection!.files.findIndex(f => f.id === file.id)
    if (indexOfFile != null) {
      setSelectedFileIndex(indexOfFile)
    }
  }

  function getSelectionOptions() {
    if (orderItem.order_item_status !== OrderItemStatus.IN_DESIGN) {
      return null
    }

    if (fileSelection.getSelectedAlbumFileIds().length > 0) {
      return <Button color='primary' onClick={onAddClick}>
        <IconPlus/>
        Bilder hinzufügen
      </Button>
    }

    return null
  }

  async function onAddClick() {
    if (isAdding) {
      return
    }
    try {
      await addPlaylistFiles(orderItem.playlist, getFilterFromSelection(fileSelection))
      await queryClient.invalidateQueries({
        queryKey: ['drive', currentDrive?.id, 'orderItems'],
      })
      setIsSelecting(false)
    } catch (e) {
      captureException(e, 'adding files')
      alert('Failed adding files ' + JSON.stringify(e))
    } finally {
      setIsAdding(false)
    }
  }

  useEffect(() => {
    if (hasNextPage && fetchNextPage && inView) {
      fetchNextPage()
    }
  }, [inView, fetchNextPage, hasNextPage])

  return <div>
    <div className='flex flex-row gap-3 px-2 mb-3'>
      <div className='flex-1'></div>
      {
        // showFilterButton &&
        // <Button className={'rounded-full ' + (isUsingFilter ? '' : 'text-primary')} disabled={isUploading} size='sm' color={isUsingFilter ? 'primary' : 'ghost'} variant={isUsingFilter ? 'outline' : undefined}
        //         onClick={() => setIsFiltering(!isFiltering)}>
        //   { isLoadingFiles && <Loading color='primary' />}
        //   {
        //     !isLoadingFiles && <>
        //       { !isUsingFilter ? <IconFilter /> : <IconFilterFilled />}
        //     </>
        //   }
        //   { !isUsingFilter ? t('common.filter') : t('common.filterIsUsed') }
        // </Button>
      }
      {
        <Button className='rounded-full' disabled={isAdding} size='sm' color='primary'
                onClick={() => {
                  setIsSelecting(!isSelecting)
                  fileSelection.setMaxFiles(orderItem.image_limit - orderItem.playlist.files.length)
                }}>
          {isSelecting ? t('common.cancel').toLowerCase() : t('common.select').toLowerCase()}
        </Button>
      }
    </div>

    <FilesListing
      files={{
        files: currentFilesCollection!.files.filter(file => !orderItem.playlist.files.find(connection => connection.file.id === file.id))
      }}
      onFileClick={isSelecting ? toggleFileSelection : onFileClick}
      fileSelection={fileSelection}
    />
    {
      currentFilesCollection &&
      <FilesSlideshow files={currentFilesCollection.files} selectedFileIndex={selectedFileIndex}
                      onSelectFileIndex={setSelectedFileIndex}/>
    }
    <div className='w-1 h-1' ref={ref}/>
    {
      isSelecting && <FileSelection fileSelection={fileSelection} hideAll hideOptions options={getSelectionOptions()}/>
    }
  </div>
}
