import OrderItemKind from '@/models/orderItemKind.ts'

export interface ProductConfigurationOption {
  id: string
  title: string
  imgSvgSrc?: string
  price?: number
}

export interface ProductConfigurationOptions {
  id: string
  title: string
  options: Array<ProductConfigurationOption>
  display?: 'select' | 'bubble'
  defaultOption: string
}

export interface ProductConfiguration {
  title: string
  description?: string
  kind: OrderItemKind
  options?: Array<ProductConfigurationOptions>
}

export function calculateTotalPrice(configuration: ProductConfiguration, chosenOptions: Record<string, string>) {
  let sum = 0
  for (const option of configuration.options!) {
    sum += option.options.find(subOption => subOption.id == chosenOptions![option.id])!.price || 0
  }

  return sum
}

export function formatPrice(price: number) {
  return price.toFixed(2).replace('.', ',') + ' €'
}
