import DriveHeader from '@/components/DriveHeader.tsx'
import { IconChevronRight } from '@tabler/icons-react'
import { ShopProductsProvider, useShopProducts } from '@/contexts/shopProductsContext.tsx'
import { useNavigate, useOutlet } from 'react-router-dom'
import { OrderItemActionsProvider, useOrderItems } from '@/contexts/orderItemContext.tsx'
import { PlaylistConfigurationProvider, useProductConfiguration } from '@/contexts/playlistConfigurationContext.tsx'
import OrderItem from '@/models/orderItem.ts'
import { calculateTotalPrice, formatPrice } from '@/models/productConfiguration.ts'
import OrderItemStatus from '@/models/orderItemStatus.ts'
import { Button } from 'react-daisyui'

export default function ShopPage() {
  const outlet = useOutlet()
  return <>
    <ShopProductsProvider>
      <OrderItemActionsProvider>
        <PlaylistConfigurationProvider>
          { outlet }
        </PlaylistConfigurationProvider>
      </OrderItemActionsProvider>
    </ShopProductsProvider>
  </>
}

export function ShopPageContent() {
  const { products } = useShopProducts()
  const { orderItems } = useOrderItems()

  return <>
    <DriveHeader title='Shop' />
    <div className='container px-4 mx-auto flex flex-col gap-3 items-start relative mt-[2rem]'>
      <h2 className='font-semibold text-2xl mb-3 text-start'>
        Deine Aufträge
      </h2>
      <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 w-full'>
        { orderItems && orderItems.map(orderItem => <OrderItemElement key={orderItem.id} orderItem={orderItem} />)}
      </div>
    </div>
    <div className='container px-4 mx-auto flex flex-col gap-3 items-start relative mt-[1rem]'>
      <h2 className='font-semibold text-2xl mb-3 text-start'>
        Produkte
      </h2>
      <div className='grid grid-cols-1 gap-3 w-full'>
        { products.map(product => <ShopProduct productId={product.id} title={product.title} description={product.description} imgSrc={product.imgSrc} key={product.id} minPrice={product.minPrice} />)}
        {/*<ShopProduct title='Fotobuch' imgSrc='/shopAssets/photo-book-preview-2.jpeg' minPrice='59,99€' />*/}
        {/*<ShopProduct title='Tassendruck' imgSrc='' />*/}
        {/*<ShopProduct title='QR-Code drucken' imgSrc='' />*/}
      </div>
    </div>
  </>
}

function OrderItemElement({ orderItem}: { orderItem: OrderItem }) {
  const navigate = useNavigate()
  const { getProduct } = useShopProducts()
  const {getConfiguration} = useProductConfiguration()
  function onOrderItemClick() {
    navigate(`item/${orderItem.id}`)
  }
  const configuration = getConfiguration(orderItem.kind)!
  const product = getProduct(orderItem.kind)
  const price = calculateTotalPrice(configuration, orderItem as unknown as Record<string, string>)
  const statusText = {
    [OrderItemStatus.IN_DESIGN]: 'Du wählst Bilder aus',
    [OrderItemStatus.IN_CHECK]: 'Wir prüfen dein Design',
    [OrderItemStatus.IN_PROGRESS]: 'Wir bearbeiten dein Design',
    [OrderItemStatus.DONE]: 'Fertig',
  }[orderItem.order_item_status]

  return <div className='bg-secondary p-2 rounded flex flex-col gap-2 shadow cursor-pointer' onClick={onOrderItemClick}>
    <img className='rounded shadow' src={product?.imgSrc} alt=""/>
    <div className='flex flex-row items-center mt-auto'>
      <div className='flex flex-col'>
        <h3 className='font-bold'>{product?.title}</h3>
        <div className='flex flex-row'>
          <div className='text-sm'>{formatPrice(price)}</div>
        </div>
        <div className='flex flex-row text-sm mt-1'>
          {statusText}
        </div>
      </div>
      <div className='ms-auto'>
        <IconChevronRight />
      </div>
    </div>
  </div>
}

function ShopProduct({ productId, title, description, imgSrc, minPrice }: { productId: string, title: string, description?: string, imgSrc?: string, minPrice?: string }) {
  const navigate = useNavigate()

  function onProductClick() {
    navigate(productId)
  }

  return <div className='rounded flex flex-row gap-2 cursor-pointer' onClick={onProductClick}>
    <div className='flex max-w-[40%] justify-center items-center bg-secondary rounded p-2'>
      <img className='rounded shadow w-full' src={imgSrc} alt=""/>
    </div>
    <div className='flex flex-col mt-auto flex-1 p-2 h-full'>
      <h3 className='font-bold text-lg'>{title}</h3>
      { description && <p className='line-limit-3 text-sm'>{description}</p> }
      <div className='flex flex-col mt-2'>
        { minPrice && <div className='flex flex-row'>
          <div>ab {minPrice}</div>
        </div>
        }
      </div>
      <div className='flex flex-row mt-2 justify-center'>
        <Button color='primary' size='sm'>Konfigurieren</Button>
        <div className='ms-auto'>
          <IconChevronRight />
        </div>
      </div>
    </div>
  </div>
}
