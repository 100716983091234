import React, { createContext, useContext } from 'react'
import OrderItemKind from '@/models/orderItemKind.ts'
import { ProductConfiguration } from '@/models/productConfiguration.ts'
import OrderItemDimension from '@/models/orderItemDimension.ts'
import OrderItemBinding from '@/models/orderItemBinding.ts'
import OrderItemPhotobookImageLimit from '@/models/orderItemPhotobookImageLimit.ts'

export interface PlaylistConfigurationActions {
  getConfiguration(kind: OrderItemKind): ProductConfiguration | undefined
}

interface PlaylistConfigurationContext {
  configurations: Array<ProductConfiguration>
}

export const PlaylistConfigurationContext = createContext<PlaylistConfigurationContext & PlaylistConfigurationActions>({
  configurations: [],
  getConfiguration(): ProductConfiguration | undefined {
    return undefined
  }
})

export function PlaylistConfigurationProvider({ children }: { children: React.ReactNode }) {
  const configurations: Array<ProductConfiguration> = [
    {
      kind: OrderItemKind.PRIMITIVE_PHOTOBOOK,
      title: 'Fotobuch',
      description: 'Du musst lediglich die Bilder auswählen und wir erstellen ein Fotobuch für dich. Den Entwurf senden wir an dich und erst sobald du es bestätigst, wird es gedruckt.',
      options: [
        {
          id: 'dimension',
          title: 'Größe',
          display: 'bubble',
          defaultOption: OrderItemDimension.DIN_A5_PORTRAIT,
          options: [
            {
              id: OrderItemDimension.DIN_A5_PORTRAIT,
              title: 'DIN A5\n(14,8x21cm)',
              imgSvgSrc: '/shopAssets/shape-din-a5.svg',
              price: 35,
            },
            // {
            //   id: 'A5-landscape',
            //   title: 'DIN A5 - Querkant (21x14,8cm)',
            // },
            {
              id: OrderItemDimension.SQUARE_20_X_20_CM,
              title: 'Quadratisch\n(20x20cm)',
              imgSvgSrc: '/shopAssets/shape-square.svg',
              price: 35,
            },
          ],
        },
        {
          id: 'binding',
          title: 'Einband',
          display: 'bubble',
          defaultOption: OrderItemBinding.HARDCOVER,
          options: [
            // {
            //   id: 'booklet',
            //   title: 'Broschüre',
            // },
            {
              id: OrderItemBinding.SOFTCOVER,
              title: 'Softcover',
              price: 0,
            },
            {
              id: OrderItemBinding.HARDCOVER,
              title: 'Hardcover (+3,00€)',
              price: 3,
            },
          ],
        },
        {
          id: 'image_limit_photo_book',
          title: 'Fotos (bis zu)',
          display: 'bubble',
          defaultOption: '25',
          options: [
            {
              id: OrderItemPhotobookImageLimit.LIMIT_25,
              title: '25',
            },
            {
              id: OrderItemPhotobookImageLimit.LIMIT_50,
              title: '50 (+15€)',
              price: 15,
            },
            {
              id: OrderItemPhotobookImageLimit.LIMIT_75,
              title: '75 (+30€)',
              price: 30,
            },
            {
              id: OrderItemPhotobookImageLimit.LIMIT_100,
              title: '100 (+45€)',
              price: 45,
            },
          ],
        },
      ],
    }
  ]

  const getConfiguration = (kind: OrderItemKind) => {
    return configurations.find(conf => conf.kind == kind)
  }

  return <PlaylistConfigurationContext.Provider value={{
    configurations,
    getConfiguration,
  }}>
    { children }
  </PlaylistConfigurationContext.Provider>
}

export function useProductConfiguration() {
  return useContext(PlaylistConfigurationContext)
}
