import { AlbumFile, prepareForLightbox } from '@/models/albumFile.ts'
import Lightbox, { ToolbarButtonKey } from 'yet-another-react-lightbox'
import * as React from 'react'
import { useMemo, useRef, useState } from 'react'
import Share from "yet-another-react-lightbox/plugins/share";
import Download from "yet-another-react-lightbox/plugins/download";
import Video from "yet-another-react-lightbox/plugins/video";
import { IconHeart, IconHeartFilled, IconMessage, IconTrash, IconX } from '@tabler/icons-react';
import { hasPermissionsDeleteComment, hasPermissionsDeleteFile } from '@/controller/permissions.ts'
import { useProfile } from '@/stores/useAuth.ts'
import { Button, Input, Loading, Modal } from 'react-daisyui'
import { useClient } from '@/stores/useClient.ts'
import { useUploadQueue } from '@/stores/useUploadQueue.ts'
import { Captions } from 'yet-another-react-lightbox/plugins'
import { t } from 'i18next'
import { useDrives } from '@/stores/useDrives.ts'
import { useFilesContext } from '@/contexts/filesContext.tsx'
import { captureException } from '@/controller/sentryHelper.ts'
import { AlbumFileComment } from '@/models/albumFileComment.ts'
import { HeyProfile } from '@/models/profile.ts'
import { HeyDrive } from '@/models/drive.ts'

export default function FilesSlideshow(
  { files, selectedFileIndex, onSelectFileIndex }:
    { files: Array<AlbumFile>, selectedFileIndex: number | null, onSelectFileIndex: (fileIndex: number | null) => void }
) {
  const slides = useMemo(() => {
    return files.map(file => prepareForLightbox(file))
  }, [files])
  const { profile } = useProfile()
  const { currentDrive } = useDrives()!
  const deleteModalRef = useRef<HTMLDialogElement>(null)
  const commentModalRef = useRef<HTMLDialogElement>(null)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isLiking, setIsLiking] = useState(false)
  const { deleteFile, like, unlike, comment } = useClient()
  const { addDeletedFiles } = useUploadQueue()
  const { updateFile } = useFilesContext()!
  const [commentContent, setCommentContent] = useState('')
  const [isCommenting, setIsCommenting] = useState(false)


  console.log('fileIndex', selectedFileIndex)
  if (selectedFileIndex == null) {
    return <></>
  }

  const currentFile = files[selectedFileIndex]

  // Happens is you delete something
  if (!currentFile) {
    return <></>
  }


  function onDeleteClick() {
    deleteModalRef.current?.showModal()

  }
  async function onDeleteConfirm() {
    setIsDeleting(true)
    try {
      const file = files[selectedFileIndex!]
      await deleteFile(file)
      addDeletedFiles([file])
      deleteModalRef.current?.close()
    } catch (e) {
      captureException(e, 'deleting file')
      console.error(e)
      alert('Error deleting file: ' + JSON.stringify(e))
    } finally {
      setIsDeleting(false)
    }
  }

  async function onLikeClick() {
    if (isLiking) {
      return
    }
    const file = files[selectedFileIndex!]
    if (file.is_demo) {
      return alert(t('common.onlyInFullVersion'))
    }

    setIsLiking(true)
    try {
      let updatedAlbumFileRes
      if (yourLike) {
        updatedAlbumFileRes = await unlike(file, yourLike)
      } else {
        updatedAlbumFileRes = await like(file)
      }
      const updatedAlbumFile: AlbumFile = updatedAlbumFileRes.data
      console.log('updatedFile:', updatedAlbumFile)
      updateFile(updatedAlbumFile)
    } catch (e) {
      captureException(e, 'liking comment')
      console.error(e)
      alert('Error deleting file: ' + JSON.stringify(e))
    } finally {
      setIsLiking(false)
    }
  }

  async function onCommentPostClick() {
    if (isCommenting) {
      return
    }

    setIsCommenting(true)

    try {
      const albumFileRes = await comment(currentFile, commentContent)
      const albumFile: AlbumFile = albumFileRes.data

      updateFile(albumFile)
      setCommentContent('')
    } catch (e) {
      captureException(e, 'posting comment')
      console.error('error posting comment')
      alert('Error posting comment: ' + JSON.stringify(e))
    } finally {
      setIsCommenting(false)
    }
  }

  async function onCommentClick() {
    if (currentFile.is_demo) {
      return alert(t('common.onlyInFullVersion'))
    }

    commentModalRef.current!.showModal()
  }

  const toolbarButtons: (ToolbarButtonKey | React.ReactNode)[] = ['close']
  if (profile && currentDrive && hasPermissionsDeleteFile(currentFile, currentDrive, profile)) {
    if (currentFile.is_demo) {
      // toolbarButtons.unshift(
      //   <div color='primary' key='demo' className='flex h-full bg-primary'>Demo Beispiel</div>
      // )
    } else {
      toolbarButtons.unshift(
        <button key='delete-file' className='yarl__button' onClick={onDeleteClick}>
          <IconTrash />
        </button>
      )
    }
  }

  const likeCount = currentFile.likes ? currentFile.likes.length : 0
  const yourLike = currentFile.likes && profile ? currentFile.likes.find(file => file.author === profile.username) : null
  toolbarButtons.unshift(<button key='like-file' className='yarl__button' onClick={onLikeClick}>
    <div className='flex flex-row justify-center items-center gap-2'>
      <span className='text-lg'>{likeCount}</span>
      { isLiking && <Loading />}
      { !isLiking && (yourLike ? <IconHeartFilled /> : <IconHeart />)}
    </div>
  </button>)

  const commentsCount = currentFile.comments ? currentFile.comments.length : 0
  toolbarButtons.unshift(<button key='comment-file' className='yarl__button' onClick={onCommentClick}>
    <div className='flex flex-row justify-center items-center gap-2'>
      <span className='text-lg'>{commentsCount}</span>
      <IconMessage />
    </div>
  </button>)

  return <>
    <Modal ref={deleteModalRef} responsive className='bg-neutral'>
      <Modal.Header>
        {t('common.deleteQst')}
        <form method="dialog">
          <button disabled={isDeleting} className="btn btn-sm btn-circle btn-ghost absolute right-5 top-5">✕</button>
        </form>
      </Modal.Header>
      <Modal.Body>
        <div className='text-center flex flex-col gap-5'>
          <p>
            {t('common.deleteRly')}
          </p>
          <div className='flex flex-col gap-3'>
            <Button className='text-[#FFFFFF]' onClick={onDeleteConfirm} color='error' disabled={isDeleting} loading={isDeleting}>
              {t('common.delete')}
            </Button>
            <form className='w-full' method="dialog">
              <Button wide color='ghost' disabled={isDeleting}>
                {t('common.cancel')}
              </Button>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>

    <Modal ref={commentModalRef} responsive className='bg-neutral'>
      <Modal.Header>
        {t('common.comments')}
        ({commentsCount})
        <form method="dialog">
          <button disabled={isCommenting} className="btn btn-sm btn-circle btn-ghost absolute right-5 top-5">
            <IconX />
          </button>
        </form>
      </Modal.Header>
      <Modal.Body>
        <div className='w-full bg-neutral px-4 pb-5 rounded-t-2xl h-[35vh] max-h-[20rem] pointer-events-auto'>
          <div className='w-full'>
            <div className='flex flex-col h-full w-full'>
              <div className='w-full max-h-[20vh] overflow-scroll'>
                { currentFile.comments && currentFile.comments.map(comment => <AlbumFileCommentElement key={comment.id} file={currentFile} comment={comment} drive={currentDrive} profile={profile} updateFile={updateFile} />)}
              </div>
              <div className='flex-1'></div>
              {
                profile && <form>
                  <div className='w-full'>
                    <Input disabled={isCommenting} className='w-full bg-neutral border-primary' value={commentContent} onChange={e => setCommentContent(e.target.value)} />
                  </div>
                  <Button fullWidth loading={isCommenting} disabled={isCommenting || !commentContent} className='my-3' color='primary' onClick={onCommentPostClick}>{t('common.postComment')}</Button>
                </form>
              }
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    <Lightbox
      open={true}
      slides={slides}
      index={selectedFileIndex}
      close={() => onSelectFileIndex(null)}
      on={{
        view: ({ index }) => onSelectFileIndex(index),
      }}
      toolbar={{
        buttons: toolbarButtons,
      }}
      plugins={[
        Video,
        Download,
        Share,
        Captions,
      ]}
    />
  </>
}

function AlbumFileCommentElement({ file, comment, profile, drive, updateFile }: { file: AlbumFile, comment: AlbumFileComment, profile: HeyProfile | null, drive: HeyDrive | null, updateFile: (file: AlbumFile) => void }) {
  const canDeleteComment = profile && drive && hasPermissionsDeleteComment(comment, drive, profile)
  const [showDelete, setShowDelete] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const { deleteComment } = useClient()

  async function onDeleteConfirm() {
    if (isDeleting) {
      return
    }

    setIsDeleting(true)
    try {
      const fileRes = await deleteComment(file, comment)
      const updatedFile: AlbumFile = fileRes.data
      setShowDelete(false)
      updateFile(updatedFile)
    } catch (e) {
      captureException(e, 'deleting comment')
      console.error(e)
      alert('Error deleting comment: ' + JSON.stringify(e))
    } finally {
      setIsDeleting(false)
    }
  }

  return <div className='flex flex-row items-center'>
    <div className='flex flex-col w-full mb-3'>
      <div>
        <span className='text-sm opacity-50 font-bold'>{comment.author_display_name}</span>
      </div>
      <span className='py-0 flex-1'>
        {comment.content}
      </span>
    </div>
    {
      canDeleteComment && <Button className='border-0' color='primary' variant='outline' size='sm' onClick={() => setShowDelete(true)}>
        <IconTrash />
      </Button>
    }
    {
      showDelete && <Modal open>
        <Modal.Header>
          {t('common.delete')}
          <button disabled={isDeleting} className="btn btn-sm btn-circle btn-ghost absolute right-5 top-5" onClick={() => setShowDelete(false)}>✕</button>
          <div className='flex flex-col gap-3 my-5'>
            <Button disabled={isDeleting} loading={isDeleting} color='error' onClick={onDeleteConfirm}>{t('common.delete')}</Button>
            <Button disabled={isDeleting} color='primary' variant='outline' onClick={() => setShowDelete(false)}>{t('common.cancel')}</Button>
          </div>
        </Modal.Header>
      </Modal>
    }
  </div>
}
