import { AlbumFile } from '@/models/albumFile.ts'
import { HeyProfile } from '@/models/profile.ts'
import { HeyDrive } from '@/models/drive.ts'
import { AlbumFileComment } from '@/models/albumFileComment.ts'

export enum HeyPermissions {
  CAN_DELETE_FILE = 'delete_file'
}

export function hasPermissionsDeleteFile(file: AlbumFile, drive: HeyDrive, profile: HeyProfile) {
  return file.author === profile.username || drive.author === profile.username
}

export function hasPermissionsDeleteComment(comment: AlbumFileComment, drive: HeyDrive, profile: HeyProfile) {
  return comment.author === profile.username || drive.author === profile.username
}
