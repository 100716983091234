import React, { createContext, useContext } from 'react'
import OrderItem from '@/models/orderItem.ts'
import { useQuery } from '@tanstack/react-query'
import { useClient } from '@/stores/useClient.ts'
import { useDrives } from '@/stores/useDrives.ts'

export interface OrderItemActions {
  refetch: () => Promise<any>
  getOrderItem(id: string): OrderItem | undefined
}

interface OrderItemActionsContext {
  orderItems: Array<OrderItem>
  isLoading: boolean
}

export const OrderItemActionsContext = createContext<OrderItemActionsContext & OrderItemActions>({
  orderItems: [],
  isLoading: false,
  getOrderItem(): OrderItem | undefined {
    return undefined
  },
  async refetch() {},
})

export function OrderItemActionsProvider({ children }: { children: React.ReactNode }) {
  const { currentDrive } = useDrives()
  const { getOrderItems } = useClient()

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['drive', currentDrive?.id, 'orderItems'],
    queryFn: async () => {
      const res = await getOrderItems(currentDrive!)

      return res.data
    },
    enabled: currentDrive != null,
  })

  function getOrderItem(id: string): OrderItem | undefined {
    return ((data || {}).order_items || []).find((orderItem: OrderItem) => orderItem.id === id)
  }

  return <OrderItemActionsContext.Provider value={{
    orderItems: data?.order_items,
    getOrderItem,
    refetch,
    isLoading,
  }}>
    { children }
  </OrderItemActionsContext.Provider>
}

export function useOrderItems() {
  return useContext(OrderItemActionsContext)
}
