import DriveHeader from '@/components/DriveHeader.tsx'
import { useShopProducts } from '@/contexts/shopProductsContext.tsx'
import { useNavigate, useParams } from 'react-router-dom'
import { useProductConfiguration } from '@/contexts/playlistConfigurationContext.tsx'
import ShopProduct from '@/models/shopProduct.ts'
import {
  calculateTotalPrice,
  formatPrice,
  ProductConfiguration,
  ProductConfigurationOptions
} from '@/models/productConfiguration.ts'
import { Badge, Button, Loading, Select } from 'react-daisyui'
import { IconArrowBack } from '@tabler/icons-react'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import OrderItem from '@/models/orderItem.ts'
import { useClient } from '@/stores/useClient.ts'
import { useDrives } from '@/stores/useDrives.ts'
import { captureException } from '@/controller/sentryHelper.ts'
import { useOrderItems } from '@/contexts/orderItemContext.tsx'

export default function ShopProductPage() {
  const { productId } = useParams()
  const { getProduct } = useShopProducts()
  // const navigate = useNavigate()
  const product = productId ? getProduct(productId) : null

  if (!product) {
    return <div>
      <DriveHeader />
      <p className='text-center'>Kein Produkt mit dieser ID</p>
    </div>
  }

  return <ShopProductPageContent product={product} />
}

function ShopProductPageContent({product}: {product: ShopProduct}) {
  const { getConfiguration } = useProductConfiguration()
  const configuration = getConfiguration(product.orderKind!)
  const navigate = useNavigate()
  const [chosenOptions, setChosenOptions] = useState<Record<string, string> | null>(null)
  const { createOrderItem } = useClient()
  const { currentDrive } = useDrives()
  const [isCreating, setIsCreating] = useState(false)
  const { refetch: refetchOrderItems} = useOrderItems()

  useEffect(() => {
    if (configuration) {
      const defaultChosenOptions: Record<string, string> = {}
      for (const option of configuration.options!) {
        defaultChosenOptions[option.id] = option.defaultOption
      }
      setChosenOptions(defaultChosenOptions)
    }
  }, [configuration])

  if (!configuration) {
    return <div>
      <DriveHeader />
      <p className='text-center'>Keine Produktkonfiguration gefunden</p>
    </div>
  }

  if (!chosenOptions) {
    return <div>
      <DriveHeader />
      <Loading />
    </div>
  }


  function onBackClick() {
    navigate('..')
  }

  async function onCreateClick() {
    if (isCreating) {
      return
    }

    const orderProperties: Partial<OrderItem> = {
      kind: product.orderKind,
    }
    for (const option of configuration!.options!) {
      // @ts-ignore Because the IDs of the options are strings
      orderProperties[option.id] = chosenOptions![option.id]
    }

    setIsCreating(true)
    try {
      const orderItemRes = await createOrderItem(currentDrive!, orderProperties)
      const orderItem: OrderItem = orderItemRes.data
      await refetchOrderItems()
      navigate(`../item/${orderItem.id}`)
    } catch (e: any) {
      captureException(e, 'creating order item')
      alert('Error creating product: ' + JSON.stringify(e.response))
      return
    } finally {
      setIsCreating(false)
    }
  }

  const totalPrice = formatPrice(calculateTotalPrice(configuration!, chosenOptions))

  return <div>
    <DriveHeader title={null} />
    <div className='container px-4 mx-auto flex flex-col gap-3 items-start relative mt-[1rem]'>
      <div className='text-sm text-primary' onClick={onBackClick}>
        <IconArrowBack />
        {t('common.back')}
      </div>
      <div className='w-full flex items-center justify-center'>
        <div className='flex flex-col gap-2 bg-secondary p-3 rounded'>
          <h1 className='text-2xl font-bold'>{product.title}</h1>
          <img className='rounded' src={product.imgSrc} alt=""/>
          <p>{configuration.description}</p>
          {
            chosenOptions && <PlaylistConfigurationOptionsElement configuration={configuration} chosenOptions={chosenOptions} setChosenOptions={setChosenOptions} />
          }
          <div className='bg-neutral shadow w-full h-[1px]' />
          <div className='flex justify-center items-center flex-col mt-3'>
          <span className='font-bold text-xl'>
            {totalPrice}
          </span>
            <span className='text-sm opacity-50'>inkl. MwSt.</span>
          </div>
          <div className='flex justify-center items-center mt-1 mb-3 flex-col'>
            <div>
              <Button color='primary' onClick={onCreateClick}>Jetzt Bilder auswählen</Button>
            </div>
            <p className='text-center text-sm mt-1 opacity-50'>Keine Sorge, das ist noch kein Kauf.</p>
            <p className='text-center text-sm mt-1 opacity-50'>Du kannst jetzt schon anfangen und ausprobieren, aber später erst abschließen.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
}

export function PlaylistConfigurationOptionsElement({configuration, chosenOptions, setChosenOptions}: {configuration: ProductConfiguration, chosenOptions: Record<string, string>, setChosenOptions?: (options: Record<string, string>) => void}) {
  const optionElements = []
  if (configuration.options) {
    for (const option of configuration.options) {
      optionElements.push(<PlaylistOptionElement key={option.id} option={option} chosenOptions={chosenOptions} setChosenOptions={setChosenOptions} />)
    }
  } else {
    return <></>
  }
  const isReadonly = setChosenOptions == null

  return <div>
    <h3 className='font-bold mb-3'>Optionen</h3>
    <div className={'flex gap-3 flex-wrap ' + (isReadonly ? 'flex-row' : 'flex-col')}>
      {optionElements}
    </div>
  </div>
}

function PlaylistOptionElement({option, chosenOptions, setChosenOptions}: {option: ProductConfigurationOptions, chosenOptions: Record<string, string>, setChosenOptions?: (options: Record<string, string>) => void}) {
  const chosenOption = chosenOptions[option.id]
  const isReadonly = setChosenOptions == null
  let optionsElements
  if (option.display === 'bubble') {
    optionsElements = <div className='flex flex-row gap-2 flex-wrap'>
      {option.options.filter(subOption => isReadonly ? subOption.id == chosenOption : true).map(subOption =>
        <Badge key={subOption.id} className={'h-[unset] p-1 px-3 flex justify-center items-center'} color={subOption.id == chosenOption ? 'primary' : undefined} onClick={() => setChosenOptions && setChosenOptions({...chosenOptions, [option.id]: subOption.id})}>
          <div className='flex flex-row gap-3'>
            {subOption.imgSvgSrc && <div className={'h-[2rem] w-[2rem] m-1 ' + (subOption.id == chosenOption ? 'bg-secondary' : 'bg-primary')} style={{
              maskImage: `url(${subOption.imgSvgSrc})`,
              maskRepeat: 'no-repeat',
              maskPosition: 'center',
              maskSize: 'contain',
            }}></div>}
            <div className='whitespace-pre text-center'>{subOption.title}</div>
          </div>
        </Badge>)
      }
    </div>
  } else {
    optionsElements = <Select size='sm' onInput={(e) => setChosenOptions && setChosenOptions({...chosenOptions, [option.id]: e.currentTarget.value})}>
      {option.options.map(subOption => <Select.Option key={subOption.id} value={subOption.id} selected={subOption.id === chosenOption}>
        {subOption.title}
      </Select.Option>)}
    </Select>
  }
  return <div key={option.id}>
    <h4 className='mb-1'>{option.title}</h4>
    {optionsElements}
  </div>
}
