import React, { createContext, useContext } from 'react'
import ShopProduct from '@/models/shopProduct.ts'
import OrderItemKind from '@/models/orderItemKind.ts'

export interface ShopProductsActions {
  getProduct(id: string): ShopProduct | undefined
}

interface ShopProductsContext {
  products: Array<ShopProduct>
}

export const ShopProductsContext = createContext<ShopProductsContext & ShopProductsActions>({
  products: [],
  getProduct(): ShopProduct | undefined {
    return undefined
  }
})

export function ShopProductsProvider({ children }: { children: React.ReactNode }) {
  const shopProducts: Array<ShopProduct> = [
    {
      id: OrderItemKind.PRIMITIVE_PHOTOBOOK,
      title: 'Fotobuch',
      description: 'Du musst lediglich die Bilder auswählen und wir erstellen ein Fotobuch für dich. Den Entwurf senden wir an dich und erst sobald du es bestätigst, wird es gedruckt.',
      imgSrc: '/shopAssets/photo-book-preview-2.jpeg',
      minPrice: '35,00€',
      orderKind: OrderItemKind.PRIMITIVE_PHOTOBOOK,
    }
  ]

  const getProduct = (id: string) => {
    return shopProducts.find(product => product.id === id)
  }

  return <ShopProductsContext.Provider value={{
    products: shopProducts,
    getProduct,
  }}>
    { children }
  </ShopProductsContext.Provider>
}

export function useShopProducts() {
  return useContext(ShopProductsContext)
}
