import {
  calculateKibiBytesPerSecond,
  isStatusFinalState,
  UploadFileStatus,
  useUploadQueue
} from '@/stores/useUploadQueue.ts'
import { Loading } from 'react-daisyui'
import { IconCheck, IconExclamationCircle } from '@tabler/icons-react'

export default function UploadProgress() {
  const { uploadQueue } = useUploadQueue()
  const isUploading = uploadQueue.some(file => !isStatusFinalState(file.status))
  // const isUploading = true

  if (!isUploading) {
    return null
  }

  const finishedCount = uploadQueue.filter(file => isStatusFinalState(file.status))
  const currentlyUploading = uploadQueue.find(file => file.status === UploadFileStatus.Uploading)
  const percentageFinished = currentlyUploading && currentlyUploading.bytesUploaded != null ? currentlyUploading.bytesUploaded / currentlyUploading.file.size * 100 : null
  // const percentageFinished = 23

  function getUploadSpeed(): { isSlow: boolean, displaySpeed: string } | null {
    if (!currentlyUploading) {
      return null
    }
    const kbPerSecond = calculateKibiBytesPerSecond(currentlyUploading.bytesHistory!)
    if (kbPerSecond == null) {
      return null
    }
    const speedAsString = kbPerSecond < 250 ? kbPerSecond.toFixed(0) + ' KB/s' : (kbPerSecond / 1024).toFixed(2) + 'MB/s'

    const isSlowThreshold = 500
    const isSlow = kbPerSecond < isSlowThreshold
    return {
      isSlow,
      displaySpeed: speedAsString,
    }
  }

  const uploadSpeed = getUploadSpeed()

  return <>
    { isUploading && <div className='bg-secondary rounded-xl relative bottom-0'>
      <div className='flex flex-row items-center h-[2rem] px-4 gap-2 justify-center relative'>
        { uploadSpeed?.isSlow && <div className='absolute top-0 h-[1.5rem] bg-secondary rounded-xl translate-y-[-100%] w-full flex items-center justify-center'>
          <div className='text-sm font-semibold flex flex-row gap-2 justify-center items-center'>
            {uploadSpeed.displaySpeed}
            {uploadSpeed.isSlow && <>
              <IconExclamationCircle className='text-primary' />
              Langsame Internetverbindung
            </>}
            {!uploadSpeed.isSlow && <>
              <IconCheck className='text-primary' />
              Gute Internetverbindung
            </>}
          </div>
        </div>}
        { (!currentlyUploading || percentageFinished == null) && <Loading color='primary' /> }
        { percentageFinished != null  &&
          <div className='text-primary font-black'>
            { Math.floor(percentageFinished) }%
          </div>
         }
        <span className='font-semibold'>{ finishedCount.length }/{ uploadQueue.length } Datei(en) hochgeladen</span>
        {/*{*/}
        {/*  currentlyUploading && <div className='inline-flex flex-row gap-3'>*/}
        {/*    <div className='max-w-[4rem] whitespace-nowrap overflow-hidden text-ellipsis'>{ currentlyUploading.file.name }</div>*/}
        {/*  </div>*/}
        {/*}*/}
      </div>
    </div> }
  </>
}
