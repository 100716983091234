import * as Sentry from '@sentry/react'
import { AxiosError } from 'axios'

export function captureException(e: any, source?: string, dataCreator?: () => any) {
  try {
    const hint = source != null && dataCreator != null ? {
      extra: {
        source: source,
        ...dataCreator(),
      },
    } : undefined

    console.error('Capturing error', source, e, hint)
    Sentry.captureException(e, {
      ...hint,
    })
  } catch (ex) {
    console.error('Failed capturing Sentry', ex)
  }
}

export function sentryFileData(file: File) {
  return {
    file: {
      name: file.name,
      size: file.size,
      lastModified: file.lastModified,
      type: file.type,
    },
  }
}

export function sentryResponseError(err: AxiosError) {
  const response = err.response ? {
    response: {
      url: err.response.config.url,
      status: err.response.status,
      data: err.response.data || null,
      headers: JSON.stringify(err.response.headers),
    }
  } : null

  return {
    axiosError: {
      status: err.status,
      ...response,
    },
  }
}
